import { CarDefect, Vehicle } from '@/interfaces'

export const InvStatuses = [
  { text: 'Ouvert', value: 'ouvert' },
  { text: 'Prospect', value: 'prospect' },
  { text: 'Rendez-vous pris', value: 'rendez-vous_pris' },
  { text: 'En attente', value: 'en_attente' },
  { text: 'En cours', value: 'en_cours' },
  { text: 'Inventaire', value: 'inventaire' },
  { text: 'Difficulté', value: 'difficulte' },
  { text: 'Deuxième relance', value: '2eme_relance' },
  { text: 'Mise en demeure', value: 'mise_en_demeure' },
  { text: 'Reglé', value: 'regle' },
  { text: 'Clôturé', value: 'clos' },
  { text: 'Annulé', value: 'annule' },
]

export const ReqStatuses = [
  { text: 'Ouvert', value: 'ouvert' },
  { text: 'Prospect', value: 'prospect' },
  { text: 'Rendez-vous pris', value: 'rendez-vous_pris' },
  { text: 'En attente', value: 'en_attente' },
  { text: 'En cours', value: 'en_cours' },
  { text: 'Préparation de vente', value: 'preparation_vente' },
  { text: 'Vente en cours', value: 'vente_en_cours' },
  { text: 'Difficulté', value: 'difficulte' },
  { text: 'Deuxième relance', value: '2eme_relance' },
  { text: 'Mise en demeure', value: 'mise_en_demeure' },
  { text: 'Reglé', value: 'regle' },
  { text: 'Clôturé', value: 'clos' },
  { text: 'Annulé', value: 'annule' },
]

export const stockStatuses = [
  {
    label: 'Entrée en stock',
    value: 'entry',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'Administratif',
    value: 'entry_admin',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'En expertise',
    value: 'expertise',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'Bijou au contrôle',
    value: 'jewelry_control',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'En vente',
    value: 'selling',
    color: '#ffffff',
    textColor: '#333333',
  },
  {
    label: 'Adjugé',
    value: 'sold',
    color: '#efe6fd',
    textColor: '#5E00E3',
  },
  {
    label: "Retiré faute d'enchère",
    value: 'removed',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'Non présenté',
    value: 'not_shown',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'En stock bloqué',
    value: 'blocked',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'A rendre',
    value: 'to_give_back',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'Restitué au vendeur',
    value: 'given_back',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: "Récupéré par l'acheteur",
    value: 'to_buyer_back',
    color: '#efe6fd',
    textColor: '#5E00E3',
  },
  {
    label: 'Regroupé',
    value: 'regrouped',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'Adjugé amiable',
    value: 'amicably_sold',
    color: '#efe6fd',
    textColor: '#5E00E3',
  },
  {
    label: 'Folle enchère / impayé',
    value: 'unfulfilled',
    color: '#c6f7d3',
    textColor: '#1f4529',
  },
  {
    label: 'Volé',
    value: 'stolen',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'Perdu',
    value: 'lost',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'Detruit',
    value: 'destroyed',
    color: '#b6b6b8',
    textColor: '#333333',
  },
  {
    label: 'Laissé sur place',
    value: 'abandonned',
    color: '#b6b6b8',
    textColor: '#333333',
  },
]

export const invoiceTypes = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Inventaire',
    value: 'inventaire',
  },
  {
    label: 'Bordereau acheteur',
    value: 'bordereau',
  },
  {
    label: 'Décompte vendeur',
    value: 'decompte',
  },
]

export const paymentStatesList = [
  { text: 'Soldé', value: 'paid', tag: 'success' },
  { text: 'Non soldé', value: 'unpaid', tag: 'danger' },
  { text: 'Partiellement soldé', value: 'partially_paid', tag: 'warning' },
  { text: 'Trop perçu', value: 'overcharge', tag: 'warning' },
]

export const paymentTypes = [
  { value: 'virement', label: 'Virement' },
  { value: 'cheque', label: 'Chèque' },
  { value: 'cb', label: 'CB' },
  { value: 'cb_interencheres', label: 'CB Interencheres' },
  { value: 'cb_drouot', label: 'CB Drouot' },
  { value: 'cb_distance', label: 'CB à distance' },    
  { value: 'espece', label: 'Espèce' },
  { value: 'compensation', label: 'Compensation' },
  { value: 'pertes_et_profits', label: 'Pertes et profits' },
]

export const countries = [
  'Afghanistan',
  'Afrique du Sud',
  'Albanie',
  'Algérie',
  'Allemagne',
  'Andorre',
  'Angola',
  'Anguilla',
  'Antarctique',
  'Antigua-et-Barbuda',
  'Antilles néerlandaises',
  'Arabie saoudite',
  'Argentine',
  'Arménie',
  'Aruba',
  'Australie',
  'Autriche',
  'Azerbaïdjan',
  'Bahamas',
  'Bahreïn',
  'Bangladesh',
  'Barbade',
  'Bélarus',
  'Belgique',
  'Belize',
  'Bénin',
  'Bermudes',
  'Bhoutan',
  'Bolivie',
  'Bosnie-Herzégovine',
  'Botswana',
  'Brésil',
  'Brunéi Darussalam',
  'Bulgarie',
  'Burkina Faso',
  'Burundi',
  'Cambodge',
  'Cameroun',
  'Canada',
  'Cap-Vert',
  'Ceuta et Melilla',
  'Chili',
  'Chine',
  'Chypre',
  'Colombie',
  'Comores',
  'Congo-Brazzaville',
  'Corée du Nord',
  'Corée du Sud',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatie',
  'Cuba',
  'Danemark',
  'Diego Garcia',
  'Djibouti',
  'Dominique',
  'Égypte',
  'El Salvador',
  'Émirats arabes unis',
  'Équateur',
  'Érythrée',
  'Espagne',
  'Estonie',
  'État de la Cité du Vatican',
  'États fédérés de Micronésie',
  'États-Unis',
  'Éthiopie',
  'Fidji',
  'Finlande',
  'France',
  'Gabon',
  'Gambie',
  'Géorgie',
  'Géorgie du Sud et les îles Sandwich du Sud',
  'Ghana',
  'Gibraltar',
  'Grèce',
  'Grenade',
  'Groenland',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernesey',
  'Guinée',
  'Guinée équatoriale',
  'Guinée-Bissau',
  'Guyana',
  'Guyane française',
  'Haïti',
  'Honduras',
  'Hongrie',
  'Île Bouvet',
  'Île Christmas',
  'Île Clipperton',
  "Île de l'Ascension",
  'Île de Man',
  'Île Norfolk',
  'Îles Åland',
  'Îles Caïmans',
  'Îles Canaries',
  'Îles Cocos - Keeling',
  'Îles Cook',
  'Îles Féroé',
  'Îles Heard et MacDonald',
  'Îles Malouines',
  'Îles Mariannes du Nord',
  'Îles Marshall',
  'Îles Mineures Éloignées des États-Unis',
  'Îles Salomon',
  'Îles Turks et Caïques',
  'Îles Vierges britanniques',
  'Îles Vierges des États-Unis',
  'Inde',
  'Indonésie',
  'Irak',
  'Iran',
  'Irlande',
  'Islande',
  'Israël',
  'Italie',
  'Jamaïque',
  'Japon',
  'Jersey',
  'Jordanie',
  'Kazakhstan',
  'Kenya',
  'Kirghizistan',
  'Kiribati',
  'Koweït',
  'Laos',
  'Lesotho',
  'Lettonie',
  'Liban',
  'Libéria',
  'Libye',
  'Liechtenstein',
  'Lituanie',
  'Luxembourg',
  'Macédoine',
  'Madagascar',
  'Malaisie',
  'Malawi',
  'Maldives',
  'Mali',
  'Malte',
  'Maroc',
  'Martinique',
  'Maurice',
  'Mauritanie',
  'Mayotte',
  'Mexique',
  'Moldavie',
  'Monaco',
  'Mongolie',
  'Monténégro',
  'Montserrat',
  'Mozambique',
  'Myanmar',
  'Namibie',
  'Nauru',
  'Népal',
  'Nicaragua',
  'Niger',
  'Nigéria',
  'Niue',
  'Norvège',
  'Nouvelle-Calédonie',
  'Nouvelle-Zélande',
  'Oman',
  'Ouganda',
  'Ouzbékistan',
  'Pakistan',
  'Palaos',
  'Panama',
  'Papouasie-Nouvelle-Guinée',
  'Paraguay',
  'Pays-Bas',
  'Pérou',
  'Philippines',
  'Pitcairn',
  'Pologne',
  'Polynésie française',
  'Porto Rico',
  'Portugal',
  'Qatar',
  'R.A.S. chinoise de Hong Kong',
  'R.A.S. chinoise de Macao',
  'régions éloignées de l’Océanie',
  'République centrafricaine',
  'République démocratique du Congo',
  'République dominicaine',
  'République tchèque',
  'Réunion',
  'Roumanie',
  'Royaume-Uni',
  'Russie',
  'Rwanda',
  'Sahara occidental',
  'Saint-Barthélémy',
  'Saint-Kitts-et-Nevis',
  'Saint-Marin',
  'Saint-Martin',
  'Saint-Pierre-et-Miquelon',
  'Saint-Vincent-et-les Grenadines',
  'Sainte-Hélène',
  'Sainte-Lucie',
  'Samoa',
  'Samoa américaines',
  'Sao Tomé-et-Principe',
  'Sénégal',
  'Serbie',
  'Serbie-et-Monténégro',
  'Seychelles',
  'Sierra Leone',
  'Singapour',
  'Slovaquie',
  'Slovénie',
  'Somalie',
  'Soudan',
  'Sri Lanka',
  'Suède',
  'Suisse',
  'Suriname',
  'Svalbard et Île Jan Mayen',
  'Swaziland',
  'Syrie',
  'Tadjikistan',
  'Taïwan',
  'Tanzanie',
  'Tchad',
  'Terres australes françaises',
  "Territoire britannique de l'océan Indien",
  'Territoire palestinien',
  'Thaïlande',
  'Timor oriental',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinité-et-Tobago',
  'Tristan da Cunha',
  'Tunisie',
  'Turkménistan',
  'Turquie',
  'Tuvalu',
  'Ukraine',
  'Union européenne',
  'Uruguay',
  'Vanuatu',
  'Venezuela',
  'Viêt Nam',
  'Wallis-et-Futuna',
  'Yémen',
  'Zambie',
  'Zimbabwe',
]

export const failures = [
  'AIRBAG A CONTROLER',
  'ANTIPOLLUTION A CONTROLER',
  'ANTIPOLLUTION A CONTROLER',
  'BANQUETTE AR INSTALLEE',
  'BOITE A REVOIR',
  'BOITE COURTE NE FONCTIONNE PAS',
  'BRUIT DANS LA TRANSMISSION DIAG',
  'BRUIT DE COURROIE',
  'BRUIT MOTEUR DIAG ALLUME VOLANT MOTEUR CLAQUEMENT EMB',
  'BRUIT MOTEUR PRECHAUFFAGE ALLUME',
  'BV A REVOIR',
  'BV ET EMB A REVOIR',
  'CABLE DOMESTIQUE ABSENT / CABLE BORNE RAPIDE PRESENT',
  'COMPTEUR ILLISIBLE COMPTEUR EN PANNE KLM INCERTAIN',
  'DEMARRAGE DIFFICILE',
  'DEMARRAGE DIFFICILE BOITE DE VITESSE DUR',
  'DEMARRE MAIS CALE',
  'DEMARRE MAIS CALE VOYANT BATTERIE ALLUME',
  'DIAG ALLUME',
  'INTERIEUR MOISI',
  'DIAG MANQUE PUISSANCE',
  'ECRAN MULTIMEDIA EN PANNE',
  'ECRAN S ALLUME PAS',
  'EMBAYAGE A REVOIR',
  'EN ETAT SANS CT ACCIDENTE',
  'EN LETAT',
  'FAP ALLUME MULTIMEDIA EN PANNE',
  "FUITE D'HUILE",
  'GRELE',
  'INJECTION + ANTIPOLLUTION + AIRBAG + FREIN DE PARKING A CONTROLER',
  'LIGNE ECHAPPEMENT HS',
  'MANQUE DE PUISSANCE / MOTEUR TOURNE MAL',
  'MANQUE FAP',
  'MANQUE LE TREUIL',
  'MOTEUR CHAUFFE',
  'MOTEUR CLAQUE',
  'MOTEUR DEMONTE MANQUE CALCULATEUR',
  'MOTEUR DEMONTE PAS DE CONTACT',
  'MOTEUR HS',
  'MOTEUR HS  VP NE S OUVRE PAS',
  'MOTEUR TREMBLE ET FUME',
  'MULTIMEDIA HS',
  'NE DEMARRE PAS  ',
  'NE DEMARRE PAS / POUR PIECES',
  'NE SE DECAPOTE PAS  ',
  "ODEUR DE BRULEE A L'EMBRAYAGE",
  'PAS DE CABLE DE CHARGE',
  'PAS DE CLEF',
  'PAS DE CLEF MOTEUR DEMONTE VENDALISE',
  'PAS DE CLES',
  'PAS DE CONTACT',
  'PB DE TRANSMISSION OU BOITE DE TRANSFERT',
  'PB MOTEUR DEMARRE MAIS CALE NOMBREUX VOYANT ALLUME',
  'PEDALE DEMBRAYAGE A REVOIR',
  "PEDALE D'EMBRAYAGE DUR",
  'PLAFOND PAS ETANCHE',
  'PORTE CONDUCTEUR S OUVRE PAS',
  'POUR PIECES / MOTEUR TOURNE / PAS DE BATTERIE / MANQUE CLES DE SECURITE',
  'PROBLEME MOTEUR',
  'PROBLEME MOTEUR DEMARRAGE DIFFICILE',
  'ROUE ARD BLOQUEE',
  'TURBO SIFFLE',
  'VITRE AVG HS',
  'VOYANT INJECTION',
  'VOYANT PRECHAUFFAGE',
  'VOYANT RADIATEUR ALLUME',
  'VP PAS ETANCHE',
]

export const equipments = [
  { value: '1PL', label: '1 PLACE' },
  { value: '1PTL', label: '1 PORTE LAT' },
  { value: '2 AB', label: '2 A.BAGS' },
  { value: '2ESS', label: '2 ESSIEUX' },
  { value: '2PL', label: '2 PLACES' },
  { value: '2PO', label: '2 PORTES' },
  { value: '2PTL', label: '2 PORTE LAT' },
  { value: '3PL', label: '3 PLACES' },
  { value: '3PO', label: '3 PORTES' },
  { value: '4 A.', label: '4 A.BAGS' },
  { value: '4PL', label: '4 PLACES' },
  { value: '4PO', label: '4 PORTES' },
  { value: '4VE', label: '4 VITRES ELECT' },
  { value: '4X4', label: '4X4' },
  { value: '5PL', label: '5 PLACES' },
  { value: '5PO', label: '5 PORTES' },
  { value: '6PL', label: '6 PLACES' },
  { value: '7PL', label: '7 PLACES' },
  { value: '8PL', label: '8 PLACES' },
  { value: '9PL', label: '9 PLACES' },
  { value: 'A.B', label: 'AIR BAG' },
  { value: 'A.B.L', label: 'AIRBAGS LATERAUX' },
  { value: 'ABAR', label: 'AIRBAG ACRRIERE' },
  { value: 'ABAV', label: 'AIRBAG AVANT' },
  { value: 'ABL', label: 'AIRBAG LATERAUX' },
  { value: 'ABLAR', label: 'AIRBAG LATERAL ARRIERE' },
  { value: 'ABMAR', label: 'AIRBAG MONTANT ARRIERE' },
  { value: 'ABMAV', label: 'AIRBAG MONTANT AVANT' },
  { value: 'ABP', label: 'AIRBAG PASSAGER' },
  { value: 'ABS', label: 'ABS' },
  { value: 'ACCAR', label: 'ACCOUDOIR ARRIERE' },
  { value: 'ACCAV', label: 'ACCOUDOIR AVANT' },
  { value: 'ACE', label: 'AVEC CARNET ENTRETIEN' },
  { value: 'AD', label: 'AD BLUE' },
  { value: 'ADC', label: 'AIDE DEMARRAGE EN COTE' },
  { value: 'ADE', label: 'ANTI DEMARRAGE' },
  { value: 'AFFTH', label: 'AFFICHAGE TETE HAUTE' },
  { value: 'AIR', label: 'SUSPENSION AIR' },
  { value: 'AL', label: 'ALARME' },
  { value: 'AMPL', label: 'AMPLIROLL' },
  { value: 'APA', label: 'ANTI PATINAGE' },
  { value: 'AR', label: 'ATTACHE REMORQUE' },
  { value: 'ARC', label: 'RADIO K7 COM VOLANT' },
  { value: 'AS', label: 'AIDE AU STATIONNEMENT' },
  { value: 'ASR', label: 'ASR' },
  { value: 'ATH', label: 'AFF TETE HAUTE' },
  { value: 'ATINT', label: 'ATELIER INTERIEUR' },
  { value: 'ATT', label: 'ATTELAGE' },
  { value: 'ATT REM', label: 'ATTACHE REMORQUE' },
  { value: 'ATTATR', label: 'ATTACHE A TETE ROTATIVE' },
  { value: 'ATTRPD', label: 'ATTACHE RAPIDE' },
  { value: 'AUTO RADIO', label: 'AUTO RADIO' },
  { value: 'B.TT', label: 'BTE TIP TRONIC' },
  { value: 'BACHE', label: 'BACHE' },
  { value: 'BARR', label: 'BARRES DE TOIT' },
  { value: 'BB', label: 'BI BENNE' },
  { value: 'BDRC', label: 'BENNE DE REPRISE CHARGEUSE' },
  { value: 'BDRCC', label: 'BENNE DE REPRISE CHARIOT COMPACT' },
  { value: 'BDRCT', label: 'BENNE DE REPRISE CHARIOT TELESCOPIQUE' },
  { value: 'BDT', label: 'BACHE DE TOIT' },
  { value: 'BEN', label: 'BENNE' },
  { value: 'BI-BENNE', label: 'BI-BENNE' },
  { value: 'BLOCAGE DE PONT', label: 'BLOCAGE DE PONT' },
  { value: 'BLU', label: 'BLUETOOTH' },
  { value: 'BRH', label: 'BRISE ROCHE HYDRAULIQUE' },
  { value: 'BV 14', label: 'BOITE 14' },
  { value: 'BV 15', label: 'BV 15' },
  { value: 'BV.10', label: 'BOITE 10' },
  { value: 'BV.16', label: 'BOITE 16' },
  { value: 'BV.18', label: 'BOITE 18' },
  { value: 'BV.4', label: 'BOITE 4' },
  { value: 'BV.5', label: 'BOITE 5' },
  { value: 'BV.6', label: 'BOITE 6' },
  { value: 'BV.8', label: 'BOITE 8' },
  { value: 'BV5', label: 'BOITE 5 VITESSES' },
  { value: 'BV6', label: 'BOITE 6 VITESSES' },
  { value: 'BV8', label: 'BOITE 8 VITESSES' },
  { value: 'BVA', label: 'BOITE AUTO' },
  { value: 'BVA 7', label: 'BVA 7' },
  { value: 'BVM', label: 'BTE MECA' },
  { value: 'BVR', label: 'BOITE VITESSE ROBOTISEE' },
  { value: 'BVSA', label: 'BTE SEMI AUTO' },
  { value: 'C', label: 'COURT' },
  { value: 'C.S', label: 'COURT/SURELEVE' },
  { value: 'CA', label: 'CHAUFFAGE AUXILIAIRE' },
  { value: 'CABA', label: 'CABINE APPROFONDIE' },
  { value: 'CAC', label: 'CABINE COURTE' },
  { value: 'CAI', label: 'CAISSE' },
  { value: 'CAM360', label: 'CAMERA 360' },
  { value: 'CAMAR', label: 'CAMERA RECUL' },
  { value: 'CAMAV', label: 'CAMERA AVANT' },
  { value: 'CAP', label: 'CABINE DUPLEX' },
  { value: 'CAP E', label: 'CAPOTE ELECTRIQUE' },
  { value: 'CAS', label: 'CAM AIDE STATIONNEMENT' },
  { value: 'CAT', label: 'CATALYSEUR' },
  { value: 'CB', label: 'CACHE BAGAGE' },
  { value: 'CBN', label: 'CABINE' },
  { value: 'CD', label: 'CD' },
  { value: 'CDELALU', label: 'CAISSE DEM ALU' },
  { value: 'CDEMFC', label: 'CAISSE DEM FIBRE COMPOSITE' },
  { value: 'CE', label: 'CARNET ENTRETIEN' },
  { value: 'CH.CD', label: 'CHARGEUR CD' },
  { value: 'CL.E', label: 'CLIM ELECTRIQUE' },
  { value: 'CLIM', label: 'CLIMATISATION' },
  { value: 'CLIMA', label: 'CLIMATISATION AUTO' },
  { value: 'CLIMBZ', label: 'CLIMATISATION BIZONE' },
  { value: 'CLIMM', label: 'CLIMATISATION MANUELLE' },
  { value: 'CN', label: 'CLIM DE NUIT' },
  { value: 'CNPY', label: 'CANOPY' },
  { value: 'COFR ELEC', label: 'COFFRE ELECTRIQUE' },
  { value: 'COU', label: 'COUCHETTE' },
  { value: 'COU2', label: '2 COUCHETTES' },
  { value: 'COUCH', label: '' },
  { value: 'CR', label: 'CAMERA DE RECUL' },
  { value: 'CTCP', label: 'CONTROLE POLLUTION TECHNIQUE' },
  { value: 'CU', label: 'CUIR' },
  { value: 'CV', label: 'COUPE VENT' },
  { value: 'DA', label: 'DIRECTION ASSISTEE' },
  { value: 'DAM', label: 'DETECTION ANGLES MORTS' },
  { value: 'DD', label: 'SYSTEME DISQUE DUR' },
  { value: 'DEFC', label: 'DEFLECTEUR DE CABINE' },
  { value: 'DEFL', label: 'DEFLECTEURS LATERAUX' },
  { value: 'DEFT', label: 'DEFLECTEUR DE TOIT' },
  { value: 'DLP', label: 'DETECTEUR LUMINOSITE PLUIE' },
  { value: 'DLUM', label: 'DETECTEUR LUMINOSITE' },
  { value: 'DPL', label: '6 PLACES' },
  { value: 'DVD', label: 'DVD' },
  { value: 'DVD AR', label: "DVD A L'ARRIERE" },
  { value: 'ECRAN APP TETE AR', label: 'ECRAN APP TETE ARR' },
  { value: 'EH', label: 'EQUIPEMENT HANDICAPE' },
  { value: 'ESP', label: 'ESP' },
  { value: 'EXT EXT', label: 'EXTINTEUR EXTERNE' },
  { value: 'EXT IN', label: 'EXTINTEUR INTERNE' },
  { value: 'FAB', label: 'FEUX ANTI BROUILLARD' },
  { value: 'FAP', label: 'FILTRE A PARTICULES' },
  { value: 'FC', label: 'FERMETURE CENTRALISEE' },
  { value: 'FCAC', label: 'FERM CENTR AVEC TELECOMM' },
  { value: 'FCD', label: 'FEUX CIRCULATION DIURNE' },
  { value: 'FPC', label: 'FOURCHE POUR CHARGEUSE' },
  { value: 'FPCC', label: 'FOURCHE POUR CHARIOT COMPACT' },
  { value: 'FPCT', label: 'FOURCHE POUR CHARIOT TELESCOPIQUE' },
  { value: 'FPL', label: '2 PLACES' },
  { value: 'FR', label: 'FRIGO DE CABINE' },
  { value: 'FRI', label: 'FRIGO' },
  { value: 'G4EN1', label: 'GODET 4 EN 1' },
  { value: 'GA', label: 'GARANTIE' },
  { value: 'GAL', label: 'GALERIE' },
  { value: 'GAS', label: 'GALETTE DE SECOURS' },
  { value: 'GC1500', label: 'GODET CURAGE 1500MM' },
  { value: 'GCC', label: 'GODET CHARGEUSE COMPACT' },
  { value: 'GCCD', label: 'GODET CROCODILE' },
  { value: 'GCCSR', label: 'GODET CONCASSEUR' },
  { value: 'GCM1500', label: 'GODET DE CURAGE MOINS DE 1500MM' },
  { value: 'GCP1500', label: 'GODET CURAGE PLUS DE 1500MM' },
  { value: 'GCRBLR', label: 'GODET CRIBLEUR' },
  { value: 'GCRG', label: 'GODET DE CURAGE' },
  { value: 'GCRGS', label: 'GODET CHARGEUSE' },
  { value: 'GGRP', label: 'GODET GRAPPIN' },
  { value: 'GIRA', label: 'GIRAFON' },
  { value: 'GLE', label: 'GLACES ELECTRIQUES' },
  { value: 'GPS', label: 'GPS' },
  { value: 'GPS EURO', label: 'GPS EUROPE' },
  { value: 'GPSC', label: 'GPS COULEUR' },
  { value: 'GPSJUKE', label: 'GPS JUKEBOX' },
  { value: 'GPSSD', label: 'GPS CARTE SD' },
  { value: 'GR1300-1500', label: 'GODET RETRO 1300-1500MM' },
  { value: 'GR1500-2000', label: 'GODET RETRO 1500-2000MM' },
  { value: 'GR150-350', label: 'GODET RETRO 150-350MM' },
  { value: 'GR2000-3000', label: 'GODET RETRO 2000-3000MM' },
  { value: 'GR350-500', label: 'GODET RETRO 350-500MM' },
  { value: 'GR500-900', label: 'GODET RETRO 500-900MM' },
  { value: 'GR900-1300', label: 'GODET RETRO 900-1300MM' },
  { value: 'GRAT', label: 'GODET RATEAU' },
  { value: 'GRUE', label: 'GRUE' },
  { value: 'HAY', label: 'HAYON' },
  { value: 'HIFI', label: 'SYSTEME HIFI' },
  { value: 'HY', label: 'HYDRAULIQUE' },
  { value: 'INTBOIS', label: 'INTERIEUR BOIS' },
  { value: 'ISO', label: 'ISOTHERME' },
  { value: 'ISOF', label: 'ISOFIXE' },
  { value: 'ISOGPE', label: 'ISOTHERME AVEC GROUPE' },
  { value: 'JA', label: 'JANTES ALU' },
  { value: 'JA13', label: 'JANTES ALU 13 POUCES' },
  { value: 'JA14', label: 'JANTES ALU 14 POUCES' },
  { value: 'JA15', label: 'JANTES ALU 15 POUCES' },
  { value: 'JA16', label: 'JANTES ALU 16 POUCES' },
  { value: 'JA17', label: 'JANTES ALU 17 POUCES' },
  { value: 'JA18', label: 'JANTES ALU 18 POUCES' },
  { value: 'JA19', label: 'JANTES ALU 19 POUCES' },
  { value: 'JA20', label: 'JANTES ALU 20 POUCES' },
  { value: 'JA21', label: 'JANTES ALU 21 POUCES' },
  { value: 'JA22', label: 'JANTES ALU 22 POUCES' },
  { value: 'JA23', label: 'JANTES ALU 23 POUCES' },
  { value: 'JAL', label: 'JANTES ALLIAGE' },
  { value: 'KIT', label: 'KIT MAIN LIBRE' },
  { value: 'LAVP', label: 'LAVE PHARES' },
  { value: 'LH', label: 'LONG / HAUT' },
  { value: 'LIM', label: 'LIMITEUR DE VITESSE' },
  { value: 'LO', label: 'LONG' },
  { value: 'LSD', label: 'LECTEUR CARTE SD' },
  { value: 'LUSB', label: 'PRISE USB' },
  { value: 'MO', label: 'MOYEN' },
  { value: 'MOH', label: 'MOYEN HAUT' },
  { value: 'MP3', label: 'RADIO MP3' },
  { value: 'N160ADS', label: 'NACELLE MUTITEL 160 ALU DS' },
  { value: 'ODR', label: 'ORDINATEUR DE BORD' },
  { value: 'OUVML', label: 'OUVERTURE MAIN LIBRE' },
  { value: 'PAB', label: 'PROJECTEURS ANTIBROUILLARD' },
  { value: 'PACK', label: 'PACK ELECTRIQUE' },
  { value: 'PACKS', label: 'PACK SPORT' },
  { value: 'PALV', label: 'PALETTE AU VOLANT' },
  { value: 'PAS ROUE DE SECOURS', label: 'PAS ROUE DE SECOURS' },
  { value: 'PASS', label: 'PARK ASSIST' },
  { value: 'PB', label: 'PORTE BAGAGES' },
  { value: 'PDIR', label: 'PHARES DIRECTIONNELS' },
  { value: 'PHJ', label: 'PHARES DE JOUR' },
  { value: 'PHXE', label: 'PHARES AU XENON' },
  { value: 'PLAT', label: 'PLATEAU' },
  { value: 'PLB', label: 'PLATEAU BASCULANT' },
  { value: 'PM', label: 'PEINTURE METAL' },
  { value: 'PN', label: 'PNEUS NEIGE' },
  { value: 'PRESSION PNEU', label: 'DEDECTEUR DE SOUS GONFLAGE DE PNEUMATIQUE' },
  { value: 'PS', label: 'PHARES SUPLEMENTAIRES' },
  { value: 'PTE LAT', label: 'PORTE LATERALE' },
  { value: 'PTES AR ELECT', label: 'PORTES AR ELECTRIQUE' },
  { value: 'PVI', label: 'PORTE VITRINE' },
  { value: 'PX', label: 'PHARES AU XENON' },
  { value: 'R', label: 'AIRBAG LATERAL AVANT' },
  { value: 'R.E', label: 'RETRO ELECT' },
  { value: 'R+L', label: 'REGUL ET LIMITEUR' },
  { value: 'RA', label: 'RADIO' },
  { value: 'RAAR', label: 'RADAR PROX AV+AR' },
  { value: 'RAB', label: 'RALENTISSEUR BOITE' },
  { value: 'RADA', label: 'RADAR ARRIERE' },
  { value: 'RAH', label: 'RAMPES HYDRAULIQUES' },
  { value: 'RALENTISSEUR', label: 'RALENTISSEUR' },
  { value: 'RAM', label: 'RALENTISSEUR MOTEUR' },
  { value: 'RANO', label: 'RADIO NON ORIGINE' },
  { value: 'RAT', label: 'RALENTISSEUR TRANSMISSION' },
  { value: 'RCAS', label: 'RADIO K7' },
  { value: 'RCC', label: 'RADIO CD COM VOLANT' },
  { value: 'RCD', label: 'RADIO CD' },
  { value: 'RCV', label: 'RADIO COMMANDE AU VOLANT' },
  { value: 'RE', label: 'RETROVISEURS ELECTRIQUES' },
  { value: 'REC', label: 'RETRO ELECT.COUL.CAISSE' },
  { value: 'RED', label: 'RETROVISEURS DEGIVRANTS' },
  { value: 'REG', label: 'REGULATEUR' },
  { value: 'RER', label: 'RETRO ELECT. RABATTABLE' },
  { value: 'RF', label: 'RIDEAUX FIT' },
  { value: 'RID', label: 'RIDELLES' },
  { value: 'RJ', label: 'ROUES JUMELEES' },
  { value: 'RK7', label: 'RADIO K7' },
  { value: 'RLE', label: 'RETRO LATERAUX ELC' },
  { value: 'RMP3', label: 'RADIO MP3' },
  { value: 'RNO', label: 'RADIO NON ORIGINE' },
  { value: 'RPA', label: 'RADAR PROXIMITE AVANT' },
  { value: 'RPAR', label: 'RADAR PROX AR' },
  { value: 'RR', label: 'RADAR RECUL' },
  { value: 'RS', label: 'ROUE SECOURS' },
  { value: 'S.C', label: 'SIEGES CHAUFFANTS' },
  { value: 'S12', label: 'SIEGES 1/3 2/3' },
  { value: 'SA', label: 'SIEGES ALCANTARA' },
  { value: 'SAD', label: 'SIEGE ARRIERE DEPOSE' },
  { value: 'SANS CLE', label: 'DEMARRAGE SANS CLE' },
  { value: 'SASAU', label: 'SYS AIDE STATIO AUTO ADHE' },
  { value: 'SC', label: 'SIEGES CUIR' },
  { value: 'SCH', label: 'SIEGES CHAUFFANTS' },
  { value: 'SE', label: 'SIEGES ELECTRIQUES' },
  { value: 'SEM', label: 'SIEGE ELEC. A MEMOIRE' },
  { value: 'SMA', label: 'SIEGE MASSANT' },
  { value: 'SN', label: 'SYSTEME DE NAVIGATION' },
  { value: 'SP', label: 'SIEGES SPORT' },
  { value: 'SSA', label: 'SIEGES SEMI ALCANTARA' },
  { value: 'SSC', label: 'SIEGES SEMI CUIR' },
  { value: 'ST', label: 'START AND STOP' },
  { value: 'SUS', label: 'SUSPENSIONS SPORT' },
  { value: 'SUSPA', label: 'SUSPENSION AIR' },
  { value: 'SUSPL', label: 'SUSPENSION LAMES' },
  { value: 'SVEN', label: 'SIEGE VENTILE' },
  { value: 'T.O', label: 'T.O' },
  { value: 'TAC', label: 'TACHYMETRE A CARTE' },
  { value: 'TAD', label: 'TACHYMETRE A DISQUE' },
  { value: 'TARR', label: 'TARIERE' },
  { value: 'TAXI', label: 'TAXI' },
  { value: 'TEL', label: 'TELEPHONE INTEGRE' },
  { value: 'TELE', label: 'TELEVISION' },
  { value: 'TEST', label: 'test' },
  { value: 'TO', label: 'TOIT OUVRANT' },
  { value: 'TOE', label: 'TOIT OUVRANT ELECTRIQUE' },
  { value: 'TOP', label: 'TOIT OUVRANT PANORAMIQUE' },
  { value: 'TOUP', label: 'TOUPIE BETON' },
  { value: 'TP', label: 'TOIT PANORAMIQUE' },
  { value: 'TRE', label: 'TREUIL' },
  { value: 'TS', label: 'TRAPPE A SKI' },
  { value: 'TU', label: 'TUNING' },
  { value: 'VE', label: 'VITRES ELECTRIQUES' },
  { value: 'VEAR', label: 'VITRES ELECTRIQUES ARRIERES' },
  { value: 'VLED', label: 'VEILLEUSES LED' },
  { value: 'VM', label: 'VOLANT MULTIFONCTIONS' },
  { value: 'VNF', label: 'VEHI NON FUMEUR' },
  { value: 'VS', label: 'VERSION SPORTIVE' },
  { value: 'VT', label: 'vitres teintees' },
  { value: 'WEB', label: 'WEBASTO' },
]

export const checklist = [
  {
    step: 'stateCheck',
    label: 'État des lieux du véhicule',
    checked: false,
    date: null,
  },
  {
    step: 'infos',
    label: 'Saisie des infos du véhicule',
    checked: false,
    date: null,
  },
  {
    step: 'papers',
    label: 'Récupération de la C.G',
    checked: false,
    date: null,
  },
  {
    step: 'ct',
    label: 'Contrôle technique passé',
    checked: false,
    date: null,
  },
  {
    step: 'cleaning',
    label: 'Lavage, nettoyage, photos',
    checked: false,
    date: null,
  },
  {
    step: 'displayInfos',
    label: 'Infos pare-brise',
    checked: false,
    date: null,
  },
  {
    step: 'gageCheck',
    label: 'Demande de non-gage',
    checked: false,
    date: null,
  },
]

export const vehicle = {
  //immatriculation: null,
  commercialType: null,
  //vin: null,
  genre: null,
  brand: null,
  year: null,
  dmec: null,
  fuelType: null,
  km: null,
  mineType: null,
  body: null,
  power: null,
  powerKw: null,
  carteGrise: null,
  carteGriseReceptionDate: null,
  color: null,
  ct: null,
  ctDate: null,
  //gage: null,
  nonMoving: null,
  uniting: null,
  seats: null,
  hasKeys: null,
  warranty: null,
  state: null,
  argusValue: null,
  marketValue: null,
  argusValueKm: null,
  newValue: null,
  checklist,
  failures: null,
  equipments: null,
  defects: [] as null | CarDefect[],
  qrCodeUrl: null,
} as Vehicle

export const vehicleBrands = [
  'AARIAC',
  'ABARTH',
  'AC',
  'ACB MORRIN',
  'ACCESS',
  'ACCORD',
  'ACF',
  'ACHILLI',
  'ACOMETIS',
  'ACTISOL',
  'ACTM',
  'ADLY MOTO',
  'ADOC',
  'ADRIA MOBIL',
  'AEBI',
  'AFT',
  'AGRAM',
  'AGRIC',
  'AIR COM',
  'AIR LIQUIDE',
  'AIRO',
  'AIRSTAR',
  'AIRSTREAM',
  'AIWAYS',
  'AIXAM',
  'AKERMAN',
  'ALBARET',
  'ALCAR',
  'ALFA ROMEO',
  'ALGECO',
  'ALIMAK',
  'ALKO',
  'ALLU',
  'ALOREM',
  'ALPEGO',
  'ALPINE',
  'ALTRAD',
  'AMAZONE',
  'AMC',
  'AMC CASTERA',
  'AMCA NOVAL',
  'AMMAN',
  'AMMANN',
  'AMS TRAILER LLP',
  'ANDAINEUR',
  'ANG',
  'ANSSEMS',
  'ANTONIO CARRARO',
  'APRILIA',
  'ARDEN',
  'ARJES',
  'ARO',
  'ARTESI',
  'ASCA',
  'ASIA',
  'ASTON MARTIN',
  'ASTON MARTIN',
  'ASTRA COPCO',
  'ATELIERS TADILLON',
  'ATLAS',
  'ATLAS COPCO',
  'ATN',
  'ATNOR',
  'AUBINEAU',
  'AUDI',
  'AUSA',
  'AUSTIN',
  'AUTRE',
  'BABY TANKER',
  'BAIER',
  'BALDOR',
  'BAOTIAN',
  'BAR ONE',
  'BARBOT',
  'BARDET',
  'BARFORD',
  'BARRETO',
  'BARYVAL',
  'BASHAN',
  'BAUMANN',
  'BCM',
  'BEAUCOEUR',
  'BEDFORD',
  'BELL',
  'BELLE',
  'BELLEGROUP',
  'BELLIER',
  'BENALU',
  'BENFORD',
  'BENMAC',
  'BENTLEY',
  'BERLIET',
  'BERRY',
  'BERTHOUD',
  'BIALLER',
  'BIEMMEDUE',
  'BINDERBERGER',
  'BIRRER',
  'BITELLI',
  'BIZIEN DESOT',
  'BLANCHARD',
  'BLOND BAUDOUIN',
  'BLUECAR',
  'BMC',
  'BMW',
  'BMW-ALPINA',
  'BOBCAT',
  'BOCKER',
  'BOCKMANN',
  'BODARD',
  'BOEGLIN',
  'BOMAG',
  'BOMAY',
  'BORO',
  'BOSCH',
  'BOSS',
  'BOTAIR',
  'BOVA',
  'BOXER',
  'BRENDON',
  'BRIAN JAMES TRAILERS',
  'BRIDGESTONE',
  'BROKK',
  'BSLT',
  'BT',
  'BTC',
  'BTL',
  'BÜCHER',
  'BUGATTI',
  'BUGNOT',
  'BULKLINER',
  'BUNKER',
  'BUNKER TRIKE',
  'BURSTNER',
  'BW TRAILERS',
  'CADILLAC',
  'CAFFINI',
  'CAGIVA',
  'CALOREX',
  'CAM CAM',
  'CAMACCIO',
  'CAMIVA',
  'CAMP AUTOMOBILITE',
  'CAP FERRET',
  'CARAVAGGI',
  'CARAVANS INTER',
  'CARLASLE',
  'CARSPEED',
  'CARTHAGO',
  'CARUELLE',
  'CASALINI',
  'CASE',
  'CASE POCLAIN',
  'CASE POTAIN',
  'CASTERA',
  'CATERHAM',
  'CATERPILLAR',
  'CAZAGRANDE',
  'CCM',
  'CELETTE',
  'CEMEC',
  'CEMON BLUMING',
  'CERES',
  'CESCA',
  'CF MOTO',
  'CHALLENGER',
  'CHANGCHAI',
  'CHATENET',
  'CHAUSSON',
  'CHEREAU',
  'CHEVAL LIBERTE',
  'CHEVROLET',
  'CHEVROLET USA',
  'CHIEFTAIN',
  'CHRYSLER',
  'CIBIE',
  'CICAS',
  'CIMA',
  'CITROEN',
  'CITROEN/BAVARIA',
  'CLAAS',
  'CLARK',
  'CLARKE',
  'CLEMENS',
  'CLEMENSON',
  'CLIMATS',
  'CLUB CAR',
  'CMAR',
  'CNH',
  'CNSE',
  'CODER',
  'COJECO',
  'COLMAR',
  'COMABI',
  'COMACCHIO',
  'COMARTH',
  'COMEUROP',
  'COMPAIR',
  'CONTAINEX',
  'CONTINENTAL',
  'COOLMOBILE',
  'COPRODIS',
  'CORGHI',
  'CORMIDI',
  'CORNE-LA',
  'COUGNAUD',
  'COURANT',
  'COUSIN',
  'CRAPIE',
  'CREYSSENS',
  'CROWN',
  'CTD',
  'CTE',
  'CUPRA',
  'DACIA',
  'DAELIM',
  'DAEWOO',
  'DAF',
  'DAIHATSU',
  'DAIMLER',
  'DANGEL',
  'DANGREVILLE',
  'DANYO POWER',
  'DATSUN',
  'DAUDIN',
  'DAVID',
  'DAXARA',
  'DECORTES',
  'DEFICO',
  'DELL',
  'DELMAG',
  'DELPHIA YACHT',
  'DELTA',
  'DEMAG',
  'DEMECO',
  'DEMICO',
  'DEMOULIN',
  'DERBI',
  'DESOT',
  'DESTA',
  'DESVOYS',
  'DEUTZ',
  'DEUTZ FAHR',
  'DEVES',
  'DEVINCI',
  'DH.DECORTES',
  'DIAMIK',
  'DIC',
  'DIECI',
  'DIETRICH CAREBUS',
  'DIGITAL METER',
  'DIMACO',
  'DIMAS',
  'DIMATEC',
  'DITCH-WITCH',
  'DKWASH',
  'DNIEPR',
  'DODGE',
  'DODGE',
  'DOKA',
  'DOKADEK 20',
  'DONKERVOORT',
  'DOOPSTADT',
  'DOOSAN',
  'DRACO',
  'DRESSER',
  'DS',
  'DUARIB',
  'DUCATI',
  'DUCATO',
  'DUE',
  'DUMA',
  'DURANDAL',
  'DUVELO',
  'DYNAPAC',
  'DYNASET',
  'E-SOLEX',
  'EASY TRICKE',
  'ECIM',
  'EDGE',
  'EDUARD',
  'EICHINGER',
  'EISEMANN',
  'ELEKTRA',
  'ELIET',
  'ELSI',
  'EMILY',
  'ENG CRANES',
  'ENGIK',
  'ENVIRUDE',
  'EPSON',
  'EQS',
  'EQUIPE',
  'ESE',
  'ESTEREL',
  'ETA',
  'ETRAMO',
  'EUPHORIA',
  'EURO REMORQUE',
  'EUROBENNE',
  'EUROFORKLIFT',
  'EUROLINERS',
  'EUROMAIR',
  'EUROMAT',
  'EUROREMORQUE',
  'EUROVOIRIE',
  'EVOBUS SETRA',
  'EXAGON MOTORS',
  'EXTEC',
  'FAREZIN',
  'FASANO',
  'FASSI',
  'FAUCHEUX',
  'FAUTRAS',
  'FAYMONVILLE',
  'FELDBINDER',
  'FELM',
  'FEMIL',
  'FENDT',
  'FENET',
  'FENWICK',
  'FERRARI',
  'FIAT',
  'FIAT HITACHI',
  'FILLIAT',
  'FINLAY',
  'FISKER',
  'FLEIGL',
  'FLEX',
  'FLIEGL',
  'FLIR',
  'FM',
  'FOG',
  'FORD',
  'FORD USA',
  'FOREDILE',
  'FORWILLIAM',
  'FOTON',
  'FOURNIER',
  'FRANC',
  'FRAPPA',
  'FRASTE',
  'FREJAT',
  'FREJAT SU',
  'FRUEHAUF',
  'FSI',
  'FTG',
  'FURUKAWA',
  'FUSO',
  'GALLIONANI',
  'GAUBERT',
  'GEHL',
  'GEM',
  'GENERAL TRAILER',
  'GENIE',
  'GENTRAIL',
  'GEOTOOL',
  'GF.GORDINI',
  'GILERA',
  'GILLARD',
  'GILLETHERS',
  'GIMSON',
  'GLUTTON',
  'GMC',
  'GNOME',
  'GODEFROY',
  'GOETT',
  'GOIAN',
  'GOLDONI',
  'GOLZ',
  'GONTRAILER',
  'GOUBARD',
  'GOUDRON',
  'GOUPIL',
  'GOURDON',
  'GRACO',
  'GRADIN',
  'GRAND AZURA',
  'GREGOIRE BESSON',
  'GRILLO',
  'GROMY',
  'GROVE',
  'GSA',
  'GUERNET',
  'GUIDETTI',
  'GUILLET',
  'GUY NOEL',
  'GYSTART',
  'HAKO',
  'HAMM',
  'HANGLONG',
  'HANOMAG',
  'HAPERT',
  'HAPI',
  'HARDI',
  'HARDOX',
  'HARLEY',
  'HAULOTTE',
  'HELI',
  'HENRA',
  'HERRIAU',
  'HIAB',
  'HIDROMEK',
  'HILMAN ROLLERS',
  'HILTI',
  'HINOWA',
  'HISTER',
  'HITACHI',
  'HIVIACO',
  'HMF',
  'HOMMELL',
  'HONDA',
  'HOTCHKISS',
  'HSM',
  'HUBIERE',
  'HUBLIERE',
  'HUMBAUR',
  'HUMMER',
  'HURE',
  'HURET',
  'HUSQVARNA',
  'HUSTER',
  'HYDROMANN',
  'HYMER',
  'HYOSUNG',
  'HYSTER',
  'HYUNDAI',
  'IBI',
  'IDEAL',
  'IFOR WILLIAMS',
  'IHI IMER',
  'IHIMER',
  'IMER',
  'INDESPENSI',
  'INFINITI',
  'INGERSOLL RAND',
  'INOWA',
  'INVENTIV',
  'IR',
  'IRISBUS',
  'IRISMAN',
  'IRMER',
  'ISEKI',
  'ISUZU',
  'ITECO',
  'IVECO',
  'J.DEERE',
  'JACOBSEN',
  'JACQUET',
  'JAGUAR',
  'JCB',
  'JDM',
  'JDM SIMPA',
  'JEANNEAU',
  'JEEP',
  'JINLING',
  'JINLUN',
  'JLG',
  'JM EXIM KONSTAL',
  'JM MOTORS',
  'JMEKA',
  'JMSTAR',
  'JOHN DEERE',
  'JONSERED',
  'JONWAY',
  'JOSKIN',
  'JPC',
  'JUNGHEINRICH',
  'KAESER',
  'KAISER',
  'KARCHER',
  'KAROSA',
  'KASSBOHRER',
  'KAWASAKI',
  'KEMPER',
  'KERENZO',
  'KERSTEN',
  'KIA',
  'KINROAD',
  'KIOTI',
  'KIVA',
  'KL',
  'KLAAS',
  'KLEEMANN',
  'KLEOS',
  'KNOTT',
  'KOBELCO',
  'KOGEL',
  'KOMATSU',
  'KOMPTECH',
  'KOSNER',
  'KRANZLE',
  'KRONE',
  'KTM',
  'KUBLER',
  'KUBOTA',
  'KUHN',
  'KUNZEL & TASIN',
  'KUNZEL & TASSIN',
  'KVERNELAND',
  'KWD',
  'KYMCO',
  'LA CAMPAGNE',
  'LA MANDRIN',
  'LACROIX',
  'LADA',
  'LAFON',
  'LAG',
  'LAIKA',
  'LAMBERET',
  'LAMBORGHINI',
  'LAMY',
  'LANCIA',
  'LANCIER',
  'LANCY',
  'LAND-ROVER',
  'LANDINI',
  'LASKI',
  'LASKY',
  'LATRE',
  'LDS',
  'LDV',
  'LEBON',
  'LECITRAILER',
  'LEGRAND',
  'LEGRAS',
  'LEROUGE',
  'LEVEQUES',
  'LEVPANO',
  'LEXUS',
  'LIBNER',
  'LIDER',
  'LIDERMAN',
  'LIEBHERR',
  'LIFTER',
  'LIFTLUX',
  'LIGER PROFESSIONAL',
  'LIGIER',
  'LINCOLN',
  'LINDE',
  'LINHAI',
  'LINTEX',
  'LMJ',
  'LOHR',
  'LOTUS',
  'LOUAULT',
  'LOUCHET',
  'LUCLA',
  'LUDIC AUTO',
  'LYNX',
  'MAC CORMICK',
  'MAC3',
  'MACO MEUDON',
  'MACO SULLAIR',
  'MACO SULLAIR',
  'MADAM',
  'MAG',
  'MAGAMOBIL',
  'MAGYAR',
  'MAHINDRA',
  'MAILLEUX',
  'MAISON DU MONDE',
  'MAISONNEUV',
  'MAJAR',
  'MAKITA',
  'MAN',
  'MANI ACCESS',
  'MANITOU',
  'MANLIFT',
  'MAPE',
  'MARELLI',
  'MARREL',
  'MASAI',
  'MASERATI',
  'MASH',
  'MASSEY FERGUSSON',
  'MASSEYFERG',
  'MATRA',
  'MAUGIERE',
  'MAUGUIERE',
  'MAX TRAILER',
  'MAYBACH',
  'MAZDA',
  'MBK',
  'MC CLOSKEY',
  'MC CORMICK',
  'MC LOUIS',
  'MCLAREN',
  'MECAGIL',
  'MECALAC',
  'MECANOREM',
  'MEGA',
  'MEGAGIL',
  'MENART',
  'MERCEDES',
  'MERCEDES-BENZ',
  'MERCERON',
  'MERCURY',
  'MERKER',
  'MERLO',
  'MESSERSI',
  'METACO',
  'METSO',
  'MG',
  'MIA ELECTRIC',
  'MIC',
  'MICHEL MARINE',
  'MICHELIN',
  'MICROCAR',
  'MIKASA',
  'MIKASU',
  'MILLENIUM',
  'MILLER',
  'MINI',
  'MISAKA',
  'MISFAT',
  'MITSUBISHI',
  'MITSUBISHI FUSO',
  'MIZAR',
  'MOIROUD',
  'MOL',
  'MONCAYO',
  'MONDOLFO',
  'MONOSEM',
  'MONTABERT',
  'MONTENEGRO',
  'MONTERY GAILLARDET',
  'MONTULL',
  'MOOVEO',
  'MORGAN',
  'MORRIS',
  'MOTO GUZZI',
  'MOTOSACOCH',
  'MOTROUD',
  'MPM',
  'MPM MOTORS',
  'MRI',
  'MULLER MIT',
  'MULTIMAX',
  'MULTITEL',
  'MUSTANG',
  'MV AGUSTA',
  'MYDATA',
  'NEOPLAN',
  'NEUSON',
  'NEW HOLLAND',
  'NICOLAS',
  'NICOM',
  'NIFTY',
  'NILFISK',
  'NISSAN',
  'NISSEN',
  'NOKKA',
  'NOOTEBOOM',
  'NOR69',
  'NOREMAT',
  'NOREXCO',
  'NOROTO PREMIUM',
  'NORTON',
  'NOVIPRO',
  'NOVUM',
  'NPK',
  'NV INTERNATIONAL',
  'NYK',
  'O ET K',
  'OLDSMOBILE',
  'OLKYMOTOR',
  'OLYMPIAN',
  'OM',
  'OM STILL',
  'OMCN',
  'OMG',
  'OMM',
  'OPEL',
  'OREC',
  'ORSI',
  'ORTHOPACTOR',
  'OSI',
  'OTTO',
  'OVA',
  'PALETCO',
  'PANHARD',
  'PANIEN',
  'PEGASUS',
  'PEGSON',
  'PEL JOB',
  'PELJOB',
  'PETERS',
  'PEUGEOT',
  'PGO',
  'PIAGGIO',
  'PINGUELY',
  'PL2M',
  'PLANCHER BASIC',
  'PLANEX',
  'PLETTAC',
  'POCLAIN',
  'POLARIS',
  'POLARIS',
  'POLESTAR',
  'POMIERS',
  'PONTIAC',
  'PONY BOSCHUNG',
  'POR TRAILER',
  'PORSCHE',
  'POTAIN',
  'POWERSCREEN',
  'PPM',
  'PRAMAC',
  'PRIMAGAZ',
  'PROBST',
  'PROMA.TEC',
  'PROMODIS',
  'PROMONT',
  'PUMA',
  'PUTZMEISTER',
  'QINGQI',
  'QTANDARD',
  'QUADDY',
  'QUATTRO',
  'QUEMERAIS',
  'QUIVOGNE',
  'RABAUD',
  'RAM',
  'RANSOME',
  'RANSOMES',
  'RAPIDO',
  'RASSANT',
  'RATIER',
  'RAUCH',
  'RAVO',
  'RAZZO',
  'REA-LYS',
  'REALYS',
  'REDEXIM',
  'REMORQUE MANDRINOISE',
  'RENAULT',
  'RENAULT TRUCKS',
  'RENAULT TRUCKS',
  'RENDERS',
  'REV',
  'REVATTO',
  'RICHIER',
  'RIDE',
  'RIDGID',
  'RIMOR',
  'RMH',
  'RMORQUES HUBIERE',
  'ROBERT',
  'ROBUSTE',
  'ROBUSTE KAISER',
  'ROBUSTEKAI',
  'ROCHE BOBOIS',
  'ROCKAIR',
  'ROLFO',
  'ROLLAND',
  'ROLLER TEAM',
  'ROLLS-ROYCE',
  'ROMUS',
  'RONDY',
  'ROPA',
  'ROTA DAIRON',
  'ROTACLEAN',
  'ROTAX',
  'ROUSSEAU',
  'ROVER',
  'RSA',
  'RUTHMAN',
  'RVI',
  'RYOBI',
  'SA ECIM',
  'SAAB',
  'SAELEN',
  'SAF',
  'SAFARI',
  'SAINTE CATHERINE',
  'SALTI',
  'SAMAS',
  'SAMBRON',
  'SAME',
  'SAMRO',
  'SANA',
  'SANDERSON',
  'SANDVIK',
  'SANICLEAN',
  'SANTANA',
  'SANY SY',
  'SANYOU',
  'SAPRATIN',
  'SARIS',
  'SATAM',
  'SATELLITE',
  'SATTELITTE',
  'SAVARY',
  'SAVELOR',
  'SAVIEM',
  'SAZE',
  'SCANIA',
  'SCANTRAX',
  'SCARAB',
  'SCHAEFF',
  'SCHLIESLING',
  'SCHMIDT',
  'SCHMITZ',
  'SCHNEIDER',
  'SCHUTZ',
  'SCHWARMBORN',
  'SCOTT',
  'SDMO',
  'SEA RAY',
  'SEADOO',
  'SEAT',
  'SECATOL',
  'SECMAIR',
  'SEG',
  'SELWOOD',
  'SEMA ELECTRIC',
  'SENNEBOGEN',
  'SENNHEISER',
  'SERMA',
  'SERRUS',
  'SGR',
  'SGS',
  'SHENKE',
  'SHIBAURA',
  'SHINERAY',
  'SIAP',
  'SICAS',
  'SICOMETAL',
  'SIMAI',
  'SIMCA',
  'SIMEX',
  'SIMPA',
  'SINCRO',
  'SIPREL',
  'SISIS',
  'SITREX',
  'SKODA',
  'SKY TEAM',
  'SKYJACK',
  'SMA',
  'SMA CONCEPT',
  'SMART',
  'SMC',
  'SMG',
  'SMG BODARD',
  'SMV',
  'SNCI',
  'SNORKEL',
  'SNORKELIFT',
  'SNOW TECH',
  'SNSE',
  'SOCOMEC',
  'SODI',
  'SODISAIR',
  'SOLEX',
  'SOMEREL',
  'SOMESTRA',
  'SOMMER',
  'SORIN',
  'SOURIAU',
  'SOVELOR',
  'SPEEFLO',
  'SPITZER EU',
  'SPYDER',
  'SRT',
  'SSANGYONG',
  'STAM',
  'STANDARD',
  'STAS',
  'STAUB',
  'STEINBOCK',
  'STENUICK',
  'STETTER',
  'STIHL',
  'STILL',
  'STILL & SAXBY',
  'STOCKMAN',
  'STRUIK',
  'SUBARU',
  'SULKY BUREL',
  'SULLAIR',
  'SUN LIVING',
  'SUN WAY',
  'SUNROLLER',
  'SUPRA',
  'SUZUKI',
  'sym',
  'TABBERT',
  'TAILIFT',
  'TAILLEFER',
  'TAKEUCHI',
  'TAOTAO',
  'TASKI',
  'TCM',
  'TEC ROTEC',
  'TECNOKAR',
  'TEMSA',
  'TENNANT',
  'TERBERG',
  'TEREX',
  'TERRUGGIA',
  'TESLA',
  'THERMOBILE',
  'THERMOTRON',
  'THERVAN',
  'THOMAS',
  'THWAITES',
  'TIERCE',
  'TISVOL',
  'TITAN',
  'TNT MOTOR',
  'TORAO DINGO',
  'TORO',
  'TOTALTRAIL',
  'TOWERMATIC',
  'TOYOTA',
  'TRAFIC',
  'TRAILOR',
  'TRAX',
  'TRIGANO',
  'TRIUMPH',
  'TROUILLET',
  'TTS',
  'TUBESCA COMABI',
  'TURBOS HOET',
  'TVR',
  'TWAITES',
  'U.EMME',
  'UFAC',
  'UMM',
  'UNIC',
  'UNIMOG',
  'UP-RIGHT',
  'UPRIGHT',
  'VALIANT',
  'VALMET',
  'VALTRA',
  'VALVAN',
  'VALVANT',
  'VAN HOOL',
  'VANGNARD',
  'VANHOOL',
  'VAUXHALL',
  'VEGA',
  'VENTURI',
  'VENTURI',
  'VEREM',
  'VERENE',
  'VERLINDE',
  'VERMEER',
  'VESPA',
  'VIBERTI',
  'VICTORY',
  'VIGOLO',
  'VIRICEL',
  'VIRVCEL',
  'VIVIER',
  'VOLGA',
  'VOLKSWAGEN',
  'VOLUMAIR',
  'VOLVO',
  'VONROAD',
  'VSN',
  'VSN REMORQ',
  'VTN',
  'WACKER',
  'WACKER NEUSON',
  'WALKER',
  'WANDERER',
  'WANJIN',
  'WESSEX',
  'WESTERN',
  'WESTFALIA',
  'WETTONCRAFT',
  'WEYCOR',
  'WEYMAR',
  'WEYTEN',
  'WIESMANN',
  'WILLIBALD',
  'WIMMER',
  'WIRTGEN',
  'WOLF',
  'WORLD LAVEUR',
  'XEROX',
  'XINLING',
  'XINYANG',
  'YALE',
  'YAM',
  'YAMAHA',
  'YANG',
  'YANMAR',
  'YIYING',
  'YSM',
  'YVMO',
  'ZANON',
  'ZETTEL MEYER',
  'ZHENHUA',
  'ZONGSHEN',
  'ZSM',
  'ZUMEX',
]

export const vehicleGenres = [
  'BATEAU',
  'BENNE AMOVIBLE',
  'CARAVANE',
  'CAMION',
  'CAR',
  'CAMPING CAR',
  '2 ROUES',
  'CONCASSEUR',
  'CONTAINER',
  'UTILITAIRE LÉGER',
  'DIVERS',
  'MATERIEL LEVAGE',
  'MACHINE AGRICOLE AUTOMOTRICE',
  'MOTO',
  'MOTO',
  '2 ROUES',
  '2 ROUES',
  '2 ROUES',
  'MTTE',
  'OUTILLAGE',
  'PLATEAU',
  'QUADRICYCLE',
  'REMORQUE',
  'REMORQUE',
  'REMORQUE PORTE ENGINS',
  'SEMI REMORQUE',
  'SRAT',
  'SEMI REMORQUE AGRICOLE',
  'SREM',
  'SRSP',
  'SRTC',
  'TRANSPORT EN COMMUN DE PERSONNE',
  'ENGINS TRAVAUX PUBLIQUES',
  'TRACTEUR',
  'TRACTEUR ROUTIER',
  'VASP',
  'VOIRIE',
  'VOITURE ENTREPRISE',
  'VÉHICULE PARTICULIER',
  'XINYANG',
]

export const vehicleFuelTypes = [
  { value: 'BI', label: 'Bi energie Electrique et Diesel' },
  { value: 'EE', label: 'Mixte Essence Electrique' },
  { value: 'EG', label: 'Mixte Essence GPL' },
  { value: 'EH', label: 'Essence hybrid' },
  { value: 'EL', label: 'Electrique' },
  { value: 'EN', label: 'Mixte Essence Gaz naturel' },
  { value: 'ES', label: 'Essence' },
  { value: 'FE', label: 'Superéthanol' },
  { value: 'GAZ', label: 'GAZ' },
  { value: 'GH', label: 'Gazole-électricité (hybride non rechargeable)' },
  { value: 'GN', label: 'Gaz naturel' },
  { value: 'GO', label: 'Diesel' },
  { value: 'GP', label: 'GPL' },
  { value: 'GZ', label: 'Gaz comprimé' },
  { value: 'NC', label: 'Non Communique' },
]

export const vehicleStates = [
  { value: 'ACC', label: 'Accidentée' },
  { value: 'EB', label: 'Bon état' },
  { value: 'EE', label: 'Excellent état' },
  { value: 'EM', label: 'État médiocre' },
  { value: 'EM/NR', label: 'Médiocre et non roulant' },
  { value: 'EPAVE', label: 'État épave' },
  { value: 'ETM', label: 'État moyen' },
  { value: 'NR', label: 'Non roulant' },
  { value: 'NR/ACC', label: 'Non roulant et accidenté' },
  { value: 'NR/EPAVE', label: 'Non roulant + épave' },
]
